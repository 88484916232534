.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Toy space style */
.cover-container {
  width: 100%;
  display: flex;
}
.delete-color {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  background-color: crimson;
}
.delete-photo button {
  border: 1rem;
  border-radius: 1rem;
  border-color: orange;
  background-color: orange;
  /* color: orange; */
  width: 100px;
  height: 40px;
}
.delete-photo button:disabled {
  border: 1rem;
  border-radius: 1rem;
  border-color: none;
  background-color: #ccc;
  /* color: orange; */
  cursor: not-allowed;
  width: 100px;
  height: 40px;
}
.delete-photo button:disabled:hover {
  cursor: not-allowed;
}

.delete-photo button:hover {
  cursor: pointer;
}
.edit-photo-form {
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;

  margin: 30px;
  justify-content: center;
}
.imgsPreview .imageHolder {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 7px 8px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
}
.imgsPreview .imageHolder .delete {
  position: absolute;
  top: 6px;
  right: 15px;
  width: 32px;
  height: 32px;
  /* opacity: 0.5; */
  border-radius: 50%;
}
.imgsPreview .imageHolder .delete:hover {
  cursor: pointer;
}
.imgsPreview .imageHolder .delete .icon {
  width: 66%;
  height: 66%;
  display: block;
  margin: 4px auto;
}
.img-wrap {
  position: relative;
}
.toy-space-picture {
  width: 300px;
  height: 300px;
  justify-content: center;
  align-items: center;
}
.toy-space-picture img {
  object-fit: cover;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
}

.toy-space-upload-cover {
  height: 150px;
  width: 150px;
  border-style: dotted;
  border-color: black;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 30px;
  justify-content: center;
}
.slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  height: 640px;
  background-repeat: no-repeat;
  background-position: center center;
}

.slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.slide-container {
  width: 100%;
  margin: auto;
}
.toy-space-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 5px;
}
.toy-space-upload-cover img {
  object-fit: contain;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
}

.upload-cover-button {
  margin: 10px;
  height: 200px;
  width: 200px;
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
}

.upload-photo {
  width: 100%;
  height: 500px;
  /* border-style: dotted;
  border-color: black; */
  overflow: scroll;
}
.information-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  width: 100%;
  cursor: pointer;
}
